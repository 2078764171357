import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"
import Image from "../Images/Images"

class Instagram extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentIndex: 0,
    }
  }

  showPrevSet = () => {
    const currentIndex =
      (this.state.currentIndex - 1 + this.props.allFile.totalCount) %
      this.props.allFile.totalCount
    this.setState({ currentIndex })
  }

  showNextSet = () => {
    const currentIndex =
      (this.state.currentIndex + 1) % this.props.allFile.totalCount
    this.setState({ currentIndex })
  }

  render() {
    const { currentIndex } = this.state
    return (
      <div>
        <div className="text-right">
          <button
            className="btn btn-primary text-light shadow mb-3 me-2"
            onClick={this.showPrevSet}
          >
            <FaArrowLeft />
          </button>
          <button
            className="btn btn-primary text-light shadow mb-3 me-2"
            onClick={this.showNextSet}
          >
            <FaArrowRight />
          </button>
          <button className="btn btn-primary fw-bold text-light shadow mb-3">
            {this.props.subject} ({this.props.allFile.totalCount})
          </button>
        </div>
        <div className="carousel slide">
          <div className="carousel-inner shadow">
            {this.props.allFile.edges.map((edge, index) => {
              let className = "carousel-item"
              if (index === currentIndex) className += "active"

              return (
                <div key={index} className={className}>
                  <div
                    className="card border-primary shadow p-3"
                    style={{
                      backgroundColor:
                        "#" + Math.random().toString(16).slice(-6) + "10",
                    }}
                  >
                    <Image
                      className="card-img-top shadow"
                      filename={`${edge.node.name}.png`}
                      style={{ borderRadius: "4px" }}
                      alt={`img-${index}`}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

const SlideCardWD = () => (
  <StaticQuery
    query={graphql`
      query {
        web_development: allFile(
          filter: {
            relativeDirectory: { regex: "/(instagram)/(web_development)/" }
          }
        ) {
          edges {
            node {
              name
            }
          }
          totalCount
        }
      }
    `}
    render={data => (
      <Instagram allFile={data.web_development} subject={`Web Development`} />
    )}
  />
)

export default SlideCardWD
