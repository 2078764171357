import React from "react"
import Title from "../Headings/Title"

const Pricing1 = () => {
  return (
    <div className="container pt-3">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-4">
          <div
            className="card shadow text-center mb-4 border-primary"
            style={{
              backgroundColor:
                "#" + Math.random().toString(16).slice(-6) + "10",
            }}
          >
            <div class="card-header py-3">
              <h4 class="my-0 fw-normal">Enterprise</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                $29<small class="text-muted fw-light">/mo</small>
              </h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>30 users included</li>
                <li>15 GB of storage</li>
                <li>Phone and email support</li>
                <li>Help center access</li>
              </ul>
              <button type="button" class="w-100 btn btn-lg btn-primary">
                Get started
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div
            className="card shadow text-center mb-4 border-primary"
            style={{
              backgroundColor:
                "#" + Math.random().toString(16).slice(-6) + "10",
            }}
          >
            <div class="card-header py-3">
              <h4 class="my-0 fw-normal">Enterprise</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                $29<small class="text-muted fw-light">/mo</small>
              </h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>30 users included</li>
                <li>15 GB of storage</li>
                <li>Phone and email support</li>
                <li>Help center access</li>
              </ul>
              <button type="button" class="w-100 btn btn-lg btn-primary">
                Get started
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Pricing2 = () => {
  return (
    <div className="container pt-3">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-4">
          <div
            className="card shadow text-center mb-4 border-primary"
            style={{
              backgroundColor:
                "#" + Math.random().toString(16).slice(-6) + "10",
            }}
          >
            <div class="card-header py-3">
              <h4 class="my-0 fw-normal">Pro</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                $15<small class="text-muted fw-light">/mo</small>
              </h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>20 users included</li>
                <li>10 GB of storage</li>
                <li>Priority email support</li>
                <li>Help center access</li>
              </ul>
              <button type="button" class="w-100 btn btn-lg btn-primary">
                Get started
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div
            className="card shadow text-center mb-4 border-primary"
            style={{
              backgroundColor:
                "#" + Math.random().toString(16).slice(-6) + "10",
            }}
          >
            <div class="card-header py-3">
              <h4 class="my-0 fw-normal">Free</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                $0<small class="text-muted fw-light">/mo</small>
              </h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>10 users included</li>
                <li>2 GB of storage</li>
                <li>Email support</li>
                <li>Help center access</li>
              </ul>
              <button type="button" class="w-100 btn btn-lg btn-primary">
                Get started
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const TabItems = [
  {
    label: "Monthly",
    text: <Pricing1 />,
  },
  {
    label: "Semester",
    text: <Pricing2 />,
  },
]

class Tabs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: 0,
    }
  }

  clickHandler = e => {
    this.setState({
      active: parseInt(e.currentTarget.attributes.num.value),
    })
  }

  render() {
    let content = ""
    const tabs = TabItems.map(({ label, text }, i) => {
      content = this.state.active === i ? text : content
      return (
        <button
          className={
            this.state.active === i
              ? "btn btn-primary m-1"
              : "btn btn-outline-primary m-1"
          }
          key={i}
          num={i}
          onClick={this.clickHandler}
        >
          {label}
        </button>
      )
    })
    return (
      <section className="faq-section">
        <Title>Pricing...</Title>
        <div className="container text-center py-5">
          <div>{tabs}</div>
          <div>{content}</div>
        </div>
      </section>
    )
  }
}

export default Tabs
