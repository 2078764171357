import React from "react"
import { FaEnvelope, FaEraser, FaLock } from "react-icons/fa"

const Newsletter = () => {
  return (
    <section className="newsletter-section">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-sm-12">
            <div
              className="card shadow border-primary"
              style={{
                backgroundColor:
                  "#" + Math.random().toString(16).slice(-6) + "10",
              }}
            >
              <div className="card-body p-5" style={{ zIndex: 3 }}>
                <h2 className="fw-bold">
                  <FaEnvelope
                    className="text-primary"
                    style={{ fontSize: "42px" }}
                  />{" "}
                  Join our newsletter and be the first to know!
                </h2>
                <p className="small">
                  Thousands of subscribers are already getting their news fresh,
                  FREE, and delivered directly to their inbox.
                </p>
                <form
                  method="POST"
                  role="presentation"
                  netlify-honeypot="bot-field"
                  data-netlify="true"
                  name="subscriber"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="subscriber" />
                  <div className="form-row py-2">
                    <div className="col-md-12 form-group mb-4">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                      />
                    </div>
                    <div className="col-md-12 form-group mb-4">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <div
                      className="btn-group btn-group-lg mb-4"
                      role="group"
                      aria-label="Send"
                    >
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg shadow"
                      >
                        <span className="mx-4">Subscribe</span>
                      </button>
                      <button
                        type="reset"
                        className="btn btn-light btn-lg shadow"
                      >
                        <FaEraser />
                      </button>
                    </div>
                    <p className="small mb-0">
                      <FaLock className="text-primary fw-bold" /> We value your
                      privacy, your information is safe with us.
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Newsletter
