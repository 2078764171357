import React from "react"
import Image from "../Images/Images"
import { Link } from "gatsby"
import Title from "../Headings/Title"
import {
  FaFacebookF,
  FaGithub,
  FaLinkedinIn,
  FaResearchgate,
  FaArrowRight,
} from "react-icons/fa"

const Items = [
  {
    authorname: "John Doe",
    role: "Manager",
    facebook: "https://www.google.com",
    github: "https://www.google.com",
    researchgate: "https://www.google.com",
    linkedin: "https://www.google.com",
    authorimage: "405",
  },
  {
    authorname: "John Doe",
    role: "CTO",
    facebook: "https://www.google.com",
    github: "https://www.google.com",
    researchgate: "https://www.google.com",
    linkedin: "https://www.google.com",
    authorimage: "404",
  },
  {
    authorname: "John Doe",
    role: "CEO",
    facebook: "https://www.google.com",
    github: "https://www.google.com",
    researchgate: "https://www.google.com",
    linkedin: "https://www.google.com",
    authorimage: "406",
  },
  {
    authorname: "John Doe",
    role: "Content Manager",
    facebook: "https://www.google.com",
    github: "https://www.google.com",
    researchgate: "https://www.google.com",
    linkedin: "https://www.google.com",
    authorimage: "405",
  },
]

const Team = () => {
  return (
    <section className="team-section">
      <Title>Team...</Title>
      <div className="container py-5">
        <div className="row justify-content-center">
          {Items.map((item, i) => {
            return (
              <div key={i} className="col-lg-3 col-md-6 col-sm-6 mb-4">
                <div
                  className="card shadow text-center p-4 border-primary"
                  style={{
                    backgroundColor:
                      "#" + Math.random().toString(16).slice(-6) + "10",
                  }}
                >
                  <Image
                    className="shadow rounded-circle mb-4"
                    filename={`${item.authorimage}.jpg`}
                    style={{
                      width: "160px",
                      height: "160px",
                      margin: "0 auto",
                    }}
                    alt={item.authorimage}
                  />
                  <h4 className="text-primary fw-bold">{item.authorname}</h4>
                  <p>{item.role}</p>

                  <div className="float-middle">
                    <a
                      href={item.facebook}
                      className="m-2 btn bg-primary text-light shadow"
                      style={{
                        fontSize: "1.2rem",
                        padding: "0px 5px 5px 5px",
                      }}
                    >
                      <FaFacebookF />
                    </a>
                    <a
                      href={item.github}
                      className="m-2 btn bg-primary text-light shadow"
                      style={{
                        fontSize: "1.2rem",
                        padding: "0px 5px 5px 5px",
                      }}
                    >
                      <FaGithub />
                    </a>
                    <a
                      href={item.researchgate}
                      className="m-2 btn bg-primary text-light shadow"
                      style={{
                        fontSize: "1.2rem",
                        padding: "0px 5px 5px 5px",
                      }}
                    >
                      <FaResearchgate />
                    </a>
                    <a
                      href={item.linkedin}
                      className="m-2 btn bg-primary text-light shadow"
                      style={{
                        fontSize: "1.2rem",
                        padding: "0px 5px 5px 5px",
                      }}
                    >
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className="col-lg-12 col-md-12 mt-3 text-center">
          <Link
            to={"/author"}
            className="btn-group btn-group-lg text-decoration-none mb-4"
          >
            <button className="btn btn-primary btn-lg shadow">
              <span className="mx-4">View All</span>
            </button>
            <button className="btn btn-light btn-lg shadow">
              <FaArrowRight />
            </button>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Team
