import React from "react"

const Item = [
  {
    image: "m3",
    title: "Section 1",
    content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
    reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
    quaerat iure quos dolorum accusantium ducimus in.`,
  },
  {
    image: "m2",
    title: "Section 2",
    content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
    reprehenderit nam as quaerat iure quos dolorum accusantium ducimus in illum vero commodi
    pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
    quidem maiores doloremque est numquam praesentium.`,
  },
  {
    image: "m1",
    title: "Section 3",
    content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
    reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
    quaerat iure quos dolorum accusantium ducimus in illum vero commodi
    pariatur? Impedit autem esse nostrum quasi.`,
  },
  {
    image: "m3",
    title: "Section 1",
    content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
    reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
    quaerat iure quos dolorum accusantium ducimus in.`,
  },
  {
    image: "m2",
    title: "Section 2",
    content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
    reprehenderit nam as quaerat iure quos dolorum accusantium ducimus in illum vero commodi
    pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
    quidem maiores doloremque est numquam praesentium.`,
  },
]

class ScrollToNode extends React.Component {
  constructor(props) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.scrollToNode = this.scrollToNode.bind(this)
  }

  scrollToNode(node) {
    node.scrollIntoView({ behavior: "smooth" })
  }

  // ref must be set on a non-react element
  // to make scrollIntoView work
  render() {
    return (
      <div>
        {Item.map((item, i) => {
          const position = 60 * i + 84
          const color = "#" + Math.random().toString(16).slice(-6) + 80
          return (
            <>
              <div id="mySidenav" class="sidenav">
                <button
                  style={{ top: position, backgroundColor: color }}
                  onClick={() => this.scrollToNode(i)}
                >
                  {item.title}
                </button>
              </div>
              <div
                ref={node => (i = node)}
                id="mySidenav-sections"
                style={{ backgroundColor: color }}
              >
                <div className="container">
                  <h1>{item.content}</h1>
                </div>
              </div>
            </>
          )
        })}
      </div>
    )
  }
}

export default ScrollToNode
