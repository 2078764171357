import React, { useEffect, useState } from "react"
import { FaArrowRight } from "react-icons/fa"
import Image from "../Images/Images"

const Parallax = ({ reference, click }) => {
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    function handleScroll() {
      setOffset(window.pageYOffset)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [offset])

  return (
    <section
      className="parallax-hero"
      ref={reference}
      style={{ marginTop: "-68px" }}
    >
      <div
        className="parallax"
        style={{
          transform: `translateY(${offset * 0.5}px)`,
        }}
      >
        <Image
          className="vh-100 vw-100"
          filename="parallax.png"
          alt="parallax"
        />
      </div>
      <div
        className="container text-center py-5"
        style={{ marginTop: "100px" }}
      >
        <div className="row justify-content-center align-items-center py-5 mb-4">
          <div className="col-md-4 order-md-2">
            <Image
              className="shadow rounded-circle mb-4"
              filename="inkredibledoc.png"
              style={{
                width: "200px",
                height: "200px",
                margin: "0 auto",
              }}
              alt="parallax"
            />
          </div>
          <div className="col-md-8">
            <p className="lead text-light">
              {/* <strong>SEPARATED THEY LIVE IN </strong> */}
              <strong>**WEBSITE UNDER CONSTRUCTION v4.1**</strong>
              <br />
              <strong>**All Contents are Dummy Text and Images**</strong>
            </p>
            <h1 className="cover-name text-light font-bold">Hello, world!</h1>
            <p className="lead text-light">
              This is a template for a simple marketing or informational website
              unique.
            </p>
            <p>
              <div
                onClick={click}
                role="presentation"
                className="btn-group btn-group-lg text-decoration-none mt-4 mb-4"
              >
                <button className="btn btn-primary btn-lg shadow">
                  <span className="mx-4">Get Started</span>
                </button>
                <button className="btn btn-light btn-lg shadow">
                  <FaArrowRight />
                </button>
              </div>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Parallax
