import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import RenderList from "../RenderList/RenderList"
import { FaArrowRight } from "react-icons/fa"
import Title from "../Headings/Title"

const RecentPosts = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMdx(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { fileAbsolutePath: { regex: "/(blog)/.*.(mdx)/" } }
          limit: 3
        ) {
          edges {
            node {
              fields {
                slug
              }
              timeToRead
              frontmatter {
                title
                date(formatString: "DD MMMM, YYYY")
                author
                category
                description
                image
              }
            }
          }
        }
      }
    `
  )
  const posts = data.allMdx

  return (
    <section className="recent-post-section">
      <Title>Recent Blog Articles...</Title>
      <div className="container py-5">
        <div className="row justify-content-center">
          {posts.edges.map(RenderList)}
          <div className="col-lg-12 col-md-12 mt-3 text-center">
            <Link
              to={"/blog"}
              className="btn-group btn-group-lg text-decoration-none mb-4"
            >
              <button className="btn btn-primary btn-lg shadow">
                <span className="mx-4">View All Posts</span>
              </button>
              <button className="btn btn-light btn-lg shadow">
                <FaArrowRight />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RecentPosts
