import React, { useRef } from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import AboutUs from "../components/AboutUs/AboutUs"
import Features from "../components/Features/Features"
import Services from "../components/Services/Services"
import Showcase from "../components/Showcase/Showcase"
import Team from "../components/Team/Team"
import Newsletter from "../components/Newsletter/Newsletter"
import RecentPosts from "../components/RecentPosts/RecentPosts"
import Faq from "../components/Faq/Faq"
import Parallax from "../components/Parallax/Parallax"
import Pricing from "../components/Pricing/Pricing"
import Portfolio from "../components/Portfolio/Portfolio"
import Tabs from "../components/Tabs/Tabs"
import Courses from "../components/Courses/Courses"
import ScrollToNode from "../components/ScrollToNode/ScrollToNode"

const IndexPage = () => {
  const scrollToDiv = ref => window.scrollTo(0, ref.current.offsetTop)
  const el1 = useRef()
  const el2 = useRef()

  return (
    <Layout>
      <Seo title="Home" />
      <Parallax reference={el1} click={() => scrollToDiv(el2)} />
      <AboutUs />
      <Features />
      <Services reference={el2} />
      <Showcase />
      <RecentPosts />
      <Portfolio />
      <Team />
      <Pricing />
      <Faq />
      <Newsletter />
      <Courses />
      <ScrollToNode />
      <Tabs />
    </Layout>
  )
}

export default IndexPage
