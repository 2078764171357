import React, { useState } from "react"
import styled from "styled-components"
import Image from "../Images/Images"

const Pagination = styled.div`
  height: auto;
  display: grid;
  grid-gap: 10px;
  padding: 10px;
  align-items: center;
  grid-template-columns: 99fr 1fr;

  > span1 {
    font-size: 22px;
  }
  span2 {
    font-size: 20px;
    display: flex;
    justify-content: center;
  }
`

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, .7)",
  zIndex: 10000,
}

const MODAL_STYLES = {
  position: "fixed",
  top: "0",
  left: "0",
  padding: "20px",
  maxWidth: "100%",
  height: "100%",
  display: "flex",
  zIndex: 10000,
}

const MODAL_DIALOG = {
  position: "relative",
  maxWidth: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}

const MODAL_CARD = {
  position: "relative",
  maxWidth: "100%",
  height: "100%",
}

const SCROLLABLE = {
  overflowY: "auto",
}

const Modal = ({ image, title, content }) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <div>
      <div
        className="card shadow mb-4 border-primary"
        onClick={() => setIsActive(!isActive)}
        role="presentation"
        style={{
          cursor: "pointer",
          backgroundColor: "#" + Math.random().toString(16).slice(-6) + "10",
        }}
      >
        <div className="card-body">
          <div className="figure shadow">
            {image ? (
              <Image
                filename={`${image}.jpg`}
                style={{
                  borderRadius: "4px",
                  height: "300px",
                  width: "auto",
                  margin: "0 auto",
                }}
                alt={image}
              />
            ) : (
              <div
                className="text-center text-light py-5"
                style={{
                  borderRadius: "4px",
                  backgroundColor: "#777",
                  height: "300px",
                }}
              >
                No Image
              </div>
            )}
            <div className="figcaption-on">
              <h4 className="text-light">{title} </h4>
            </div>
          </div>
        </div>
      </div>

      {isActive && (
        <>
          <div style={OVERLAY_STYLES} />
          <div style={MODAL_STYLES}>
            <div style={MODAL_DIALOG}>
              <div className="card" style={MODAL_CARD}>
                <div className="card-header">
                  <Pagination>
                    <span1 className="fw-bold text-primary" type="button">
                      {title}
                    </span1>
                    <span2
                      type="button"
                      class="btn-close"
                      aria-label="Close"
                      onClick={() => setIsActive(!isActive)}
                    ></span2>
                  </Pagination>
                </div>
                <div style={SCROLLABLE}>
                  <div className="card-body">
                    <p className="card-text">{content}</p>
                  </div>
                </div>

                {/* <div class="card-footer">
                  <button
                    className="btn btn-primary"
                    onClick={() => setIsActive(!isActive)}
                  >
                    Close
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Modal
