import React from "react"
import Title from "../Headings/Title"
import {
  FaAccusoft,
  FaCamera,
  FaGalacticSenate,
  FaGavel,
  FaPenFancy,
  FaSafari,
} from "react-icons/fa"

const Items = [
  {
    icon: <FaAccusoft />,
    title: "Skill of the Century",
    content:
      "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.",
  },
  {
    icon: <FaCamera />,
    title: "Skill of the Century",
    content:
      "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. Lorem ipsum, or lipsum as it is sometimes known.",
  },
  {
    icon: <FaGalacticSenate />,
    title: "Skill of the Century",
    content:
      "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print.",
  },
  {
    icon: <FaGavel />,
    title: "Skill of the Century",
    content:
      "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. Lorem ipsum, or lipsum as it is sometimes known.",
  },
  {
    icon: <FaPenFancy />,
    title: "Skill of the Century",
    content:
      "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. Lorem ipsum, or lipsum as it is sometimes known.",
  },
  {
    icon: <FaSafari />,
    title: "Skill of the Century",
    content:
      "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print.",
  },
]

const Services = ({ reference }) => {
  return (
    <section className="services-section" ref={reference}>
      <Title>Services...</Title>
      <div className="container py-5">
        <div className="row justify-content-center">
          {Items.map((item, i) => {
            return (
              <div key={i} className="col-lg-4 col-md-6">
                <div
                  className="card shadow text-center mb-4 border-primary"
                  style={{
                    backgroundColor:
                      "#" + Math.random().toString(16).slice(-6) + "10",
                  }}
                >
                  <div className="card-body">
                    <h1 className="p-4" style={{ fontSize: "80px" }}>
                      {item.icon}
                    </h1>
                    <h4 className="text-primary fw-bold">{item.title}</h4>
                    <p>{item.content}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Services
