import React from "react"
import Image from "../Images/Images"
import { graphql, StaticQuery, Link } from "gatsby"
import {
  FaArrowRight,
  FaChevronCircleLeft,
  FaChevronCircleRight,
} from "react-icons/fa"
import Title from "../Headings/Title"

class CoolThing extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentIndex: 0,
    }
  }

  showPrevSet = () => {
    const currentIndex =
      (this.state.currentIndex - 1 + this.props.allFile.totalCount) %
      this.props.allFile.totalCount
    this.setState({ currentIndex })
  }

  showNextSet = () => {
    const currentIndex =
      (this.state.currentIndex + 1) % this.props.allFile.totalCount
    this.setState({ currentIndex })
  }

  render() {
    const { currentIndex } = this.state
    return (
      <section className="showcase-section">
        <Title>Showcase...</Title>
        <div className="container py-5">
          <div className="carousel slide carousel-fade">
            <div className="carousel-inner shadow">
              {this.props.allFile.edges.map((edge, index) => {
                let className = "carousel-item"
                if (index === currentIndex) className += "active"
                let slide = index + 1

                return (
                  <div key={index} className={className}>
                    <div
                      className="card border-primary shadow p-3"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <Image
                        className="card-img-top shadow"
                        filename={`${edge.node.name}.png`}
                        style={{ borderRadius: "4px", height: "500px" }}
                        alt={`img-${index}`}
                      />

                      <h5 className="carousel-indicators text-light text-shadow bg-transparent mb-4">
                        {slide}/{this.props.allFile.totalCount}
                      </h5>
                    </div>
                  </div>
                )
              })}
            </div>
            <button
              className="carousel-control-prev"
              onClick={this.showPrevSet}
            >
              <FaChevronCircleLeft size={36} fill="#7a5bad" />
            </button>
            <button
              className="carousel-control-next"
              onClick={this.showNextSet}
            >
              <FaChevronCircleRight size={36} fill="#7a5bad" />
            </button>
          </div>
          <div className="col-lg-12 col-md-12 mt-3 text-center">
            <Link
              to={"/gallery"}
              className="btn-group btn-group-lg text-decoration-none mt-4 mb-4"
            >
              <button className="btn btn-primary btn-lg shadow">
                <span className="mx-4">View All Photos</span>
              </button>
              <button className="btn btn-light btn-lg shadow">
                <FaArrowRight />
              </button>
            </Link>
          </div>
        </div>
      </section>
    )
  }
}

const Showcase = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { relativeDirectory: { regex: "/(diagrams)/" } }) {
          edges {
            node {
              name
            }
          }
          totalCount
        }
      }
    `}
    render={data => <CoolThing allFile={data.allFile} />}
  />
)

export default Showcase
