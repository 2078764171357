import React from "react"
import Title from "../Headings/Title"
import SlideCardDE from "../SlideCard/SlideCardDE"
import SlideCardWD from "../SlideCard/SlideCardWD"

const Items = [
  {
    order: "order-md-0",
    slide: <SlideCardWD />,
    title: "First featurette heading.",
    titleinprimary: "It’ll blow your mind.",
    content:
      "Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id ligula porta felis euismod semper. Praesent commodo cursus magna, vel scelerisque nisl consectetur. Fusce dapibus, tellus ac cursus commodo.",
  },
  {
    order: "order-md-1",
    slide: <SlideCardDE />,
    title: "Oh yeah, it’s that good.",
    titleinprimary: "See for yourself.",
    content:
      "Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id ligula porta felis euismod semper. Praesent commodo cursus magna, vel scelerisque nisl consectetur. Fusce dapibus, tellus ac cursus commodo.",
  },
  {
    order: "order-md-0",
    slide: <SlideCardWD />,
    title: "And lastly, this one.",
    titleinprimary: "Checkmate.",
    content:
      "Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id ligula porta felis euismod semper. Praesent commodo cursus magna, vel scelerisque nisl consectetur. Fusce dapibus, tellus ac cursus commodo.",
  },
]

const Features = () => {
  return (
    <section className="features-section">
      <Title>Features...</Title>
      <div className="container py-5">
        {Items.map((item, i) => {
          return (
            <div
              key={i}
              className="row justify-content-center align-items-center pb-5 mb-4"
            >
              <div className={`col-md-7 ${item.order}`}>
                <h2 className="heading fw-bold">
                  {item.title}{" "}
                  <span className="text-primary">{item.titleinprimary}</span>
                </h2>
                <p>{item.content}</p>
              </div>
              <div className="col-md-5">{item.slide}</div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Features
